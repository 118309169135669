import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import * as classes from "./ProductItem.module.css";

const ProductItem = (props) => {
  const price = props.node.priceRangeV2.minVariantPrice.amount;
  const comparePrice = props.node.variants[0].compareAtPrice;
  const currencyCode = props.node.priceRangeV2.minVariantPrice.currencyCode;

  const priceDiv =
    parseInt(price) < parseInt(comparePrice) ? (
      <>
        <span className={classes.salePrice}>
          ${comparePrice} {currencyCode}
        </span>
        ${price}0 {currencyCode}
      </>
    ) : (
      `$${comparePrice} ${currencyCode}`
    );

  return (
    <article className={classes.productCard}>
      <Link to={`/shop/${props.node.handle}`}>
        <GatsbyImage
          image={getImage(props.node.featuredImage.localFile)}
          alt="product"
          className={classes.productImage}
        />
        <div className={classes.productInfo}>
          <span className={classes.preTitle}>Goriot</span>
          <h1 className={classes.title}>{props.node.title}</h1>
          <span className={classes.price}>{priceDiv}</span>
        </div>
      </Link>
    </article>
  );
};

export default ProductItem;
