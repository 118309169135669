import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo/seo";
import AllProducts from "../components/Products/AllProducts";

const ShopPage = ({ data }) => {
  return (
    <>
      <Seo title="Shop" description="Shop for Goriot" />
      <AllProducts data={data} />
    </>
  );
};

export const query = graphql`
  query MyQuery {
    products: allShopifyProduct {
      edges {
        node {
          id
          handle
          title
          variants {
            compareAtPrice
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  blurredOptions: { width: 10 }
                  placeholder: NONE
                  quality: 100
                )
              }
            }
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export default ShopPage;
