import React from "react";
import { Link } from "gatsby";

import * as classes from "./AllProducts.module.css";
import ProductItem from "./ProductItem";

const AllProducts = (props) => {
  return (
    <div className={classes.outer}>
      <div className={classes.middle}>
        <div className={classes.announce}>
          <p>Note:</p>
          <ul>
            <li>
              <Link to="/stickers">Stickers with every order</Link>
            </li>
            <li>Shipping worldwide</li>
          </ul>
        </div>
        <div className={classes.productsSection}>
          {props.data.products.edges.map((edge, i) => (
            <ProductItem key={i} node={edge.node} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
